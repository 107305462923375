import React,{useState} from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

function Applicant() { 
        const formik = useFormik({
          initialValues: {
              company: "Blnk",
              companyAddress: "Zayed",
              name: "Omar",
              lastName: "Osman",
              email: "omarosman232000@gmail.com",
              phone: "01062101060",
              companyBio: "FinTech"
          },
          validationSchema: Yup.object({
            company: Yup.string()
              .max(20, 'Must be 15 characters or less')
              .required('This field is required'),
              companyAddress: Yup.string()
              .max(20, 'Must be 20 characters or less')
              .required('This field is required'),
              name: Yup.string()
              .max(15, 'Must be 15 characters or less')
              .required('This field is required'),
              lastName: Yup.string()
              .max(15, 'Must be 15 characters or less')
              .required('This field is required'),
              email: Yup.string().email('Invalid email address').required('This field is required'),
              phone: Yup.number()
              .required('This field is required'),
              companyBio: Yup.string()
              .min(5, 'Must be 5 characters or more')
              .required('This field is required'),
          }),
        onSubmit: async(values, { resetForm }) => {
          try {
            const docuSignAPILocalhost = "http://localhost:3001/test";
            const docuSignAPIProd = "https://5jnlptmh6j.execute-api.us-east-1.amazonaws.com/test";
            const docuSignLambdaProd = "https://cboxhy97ll.execute-api.us-east-1.amazonaws.com/v1/docusign";
            const response = await axios.post(docuSignLambdaProd, JSON.stringify(values));
            const response_body = JSON.parse(response.data.body);
            const docusign_url = response_body.url;
            window.location.replace(docusign_url);
          } catch(error) {
            console.log(`Failed wirth error: ${error}`);
          }
        },
      });
      
  return (
    <div style={{backgroundColor:'#080b1a',height:'100vh',padding:'10px',paddingTop:'60px'}}>
        <div className='row form-application' style={{ display:'grid',gridTemplateColumns:'1.5fr 2fr', width:'80%',margin:'0 auto',borderRadius:'10px',backgroundColor:'#fff'}}>
            
        <img className='col-md-3 col' src={require('../../assets/images/service/02.jpg')} style={{height:'100%',width:'100%',borderTopLeftRadius:'10px',borderBottomLeftRadius:'10px',padding:'0'}}/>
        
  <form className='col-md-9 col' onSubmit={formik.handleSubmit} style={{width:'93%',borderRadius:'10px',padding:'20px',backgroundColor:'#fff'}}>
    <h2 className='text-info ms-3'>Application</h2>
  <div className='row'>
        <div className='mb-2 col'>
          <label className="form-label" htmlFor="company">Company Name:</label>
          <input
          className="form-control"
          type="text"
          id="company"
          name="company"
          placeholder="Company Name"
          value={formik.values.company}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
          />
              {formik.touched.company && formik.errors.company ? (
           <div className='text-danger'>{formik.errors.company}</div>
         ) : null}
        </div>
        <div className='mb-2 col'>
          <label className="form-label" htmlFor="companyAddress">Company Address:</label>
          <input
          className="form-control"
          type="text"
          id="companyAddress"
          name="companyAddress"
          placeholder="Company Address"
          value={formik.values.companyAddress}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
          />
              {formik.touched.companyAddress && formik.errors.companyAddress ? (
                  <div className='text-danger'>{formik.errors.companyAddress}</div>
                  ) : null}
        </div>
  </div>
   <div className='row'>
       <div className='mb-2 col'>
         <label className="form-label" htmlFor="name">First Name:</label>
         <input
         className="form-control"
         type="text"
         id="name"
         name="name"
         placeholder="First Name"
         value={formik.values.name}
         onChange={formik.handleChange}
         onBlur={formik.handleBlur}
         required
         />
             {formik.touched.name && formik.errors.name ? (
                 <div className='text-danger'>{formik.errors.name}</div>
                 ) : null}
       </div>
       <div className='mb-2 col'>
         <label className="form-label" htmlFor="lastName">Last Name:</label>
         <input
         className="form-control"
         type="text"
         id="lastName"
         name="lastName"
         placeholder="Last Name"
         value={formik.values.lastName}
         onChange={formik.handleChange}
         onBlur={formik.handleBlur}
         required
         />
             {formik.touched.lastName && formik.errors.lastName ? (
                 <div className='text-danger'>{formik.errors.lastName}</div>
                 ) : null}
       </div>
   </div>
    <div className='row'>
          <div className='mb-2 col'>
            <label className="form-label" htmlFor="email">Email:</label>
            <input
            className="form-control"
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
            />
                {formik.touched.email && formik.errors.email ? (
                    <div className='text-danger'>{formik.errors.email}</div>
                    ) : null}
          </div>
          <div className='mb-2 col'>
            <label className="form-label" htmlFor="phone">Phone:</label>
            <input
            className="form-control"
              type="tel"
              id="phone"
              name="phone"
              placeholder="Phone number"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              />
                {formik.touched.phone && formik.errors.phone ? (
                    <div className='text-danger'>{formik.errors.phone}</div>
                    ) : null}
          </div>
    </div>
      <div className='mb-2'>
        <label className="form-label" htmlFor="companyBio">Company Bio:</label>
        <textarea
        className="form-control"
        id="companyBio"
        name="companyBio"
        placeholder="Company Bio"
        value={formik.values.companyBio}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        required
        />
            {formik.touched.companyBio && formik.errors.companyBio ? (
                <div className='text-danger'>{formik.errors.companyBio}</div>
                ) : null}
      </div>
      <button type="submit" className="btn btn-primary">Submit</button>
    </form>
                </div>
    </div>
  )
}

export default Applicant
